import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getAllProgram, getAllTypeForm, getAllTypeFormDataForSaveApplication } from "../../network/api"
import { useNavigate } from 'react-router-dom';
import {useAuth} from '../modules/auth'

interface FormRendererProps {
    item: any;
    handleShowModal: Function;
    handleShowModalConfirmation: Function;
    handleShowModalConfirmationApproveDeny: Function;
    hasAdmin: boolean;
    formsPage: boolean;
}

interface TypeformData {
    id: number;
    form_id: string;
    response_type: string;
    name: string;
    overview: string;
    category: string;
    action: string;
}

export const NewAndIncAppFormRenderer: React.FC<FormRendererProps> = ({
    item,
    handleShowModal,
    handleShowModalConfirmation,
    handleShowModalConfirmationApproveDeny,
    hasAdmin,
    formsPage,
}) => {
    const navigate = useNavigate()
    const {currentUser} = useAuth()

    const [getTypeformData, setTypeformData] = useState<TypeformData[]>([]);
    const [getFormsByProgram, setFormsByProgram] = useState<{ [key: number]: number[] }>({});
    const [getTypeForm, setTypeform] = useState<TypeformData[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Fetch typeform data
                const { data: typeformData } = await getAllTypeForm();
                const categoryForms = typeformData.filter((form: { category: string; }) => form.category === "application");
    
                setTypeformData(categoryForms);
    
                const [typeformDataRes] = await Promise.all([
                    getAllTypeFormDataForSaveApplication(),
                ]);
    
                setTypeform(typeformDataRes.data)
    
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
      
        fetchData();
    }, [item]);
    
    useEffect(() => {
        const fetchContactProgramsAndActiveForms = async () => {
            setIsLoading(true);
        
            try {
                // Fetch contact programs
                const contactProgramsPromise = Promise.resolve(
                    Array.isArray(item)
                        ? item.flatMap((entry: { selectedProgram?: { id: number } }) => 
                                entry.selectedProgram?.id || [])
                        : item.selectedProgram
                        ? [item.selectedProgram.id]
                        : []
                );
    
                // Fetch active forms
                const activeFormsPromise = contactProgramsPromise.then((programIds) =>
                    getAllProgram().then(response => 
                        response.data.filter((form: { program_id: any; isActive: boolean }) =>
                            form.isActive && programIds.includes(form.program_id)
                        )
                    )
                );
                
                const [contactPrograms, activeForms] = await Promise.all([contactProgramsPromise, activeFormsPromise]);
        
                // Process active forms based on fetched contact programs
                const contactProgramsSet = new Set(contactPrograms);
                const filteredActiveForms = activeForms.filter((form: { program_id: number; }) => contactProgramsSet.has(form.program_id));
                const formsByProgram = filteredActiveForms.reduce(
                    (acc: { [x: string]: any[] }, form: { program_id: string | number, form_id: any }) => {
                        if (!acc[form.program_id]) {
                            acc[form.program_id] = [];
                        }
                        acc[form.program_id].push({
                            form_id: form.form_id,
                            completed: false
                        });
                        return acc;
                    },
                    {}
                );
    
                const contactProgramsArray = Array.from(contactProgramsSet as Set<string | number>);
                for (const programId of contactProgramsArray) {
                    if (!formsByProgram[programId]) {
                        formsByProgram[programId] = [];
                }
                      
                const form9Exists = formsByProgram[programId].some((form: { form_id: number }) => form.form_id === 9);
                    if (!form9Exists) {
                        formsByProgram[programId].push({
                            form_id: 9,
                            completed: false,
                        });
                    }
                }
    
                setFormsByProgram(formsByProgram);    
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
      
        fetchContactProgramsAndActiveForms();
    }, [item]);

    const navigateForms = async (student_uuid: string, id: any, program_uuid: string, form_id: string|number, completedForms: any[], sf_id: string) => {
        try {
            setIsLoading(true);
            const data  = getTypeformData
        
            const formIdToFormId = data.reduce((acc: { [key: number]: string }, form: any) => {
                acc[form.id] = form.form_id;
                return acc;
            }, {});
        
            const matchedForm = data.find((form: any) => form.id === form_id);
        
            if (!matchedForm) {
                console.error('No matching form_id found.')
                return;
            }
        
            const formsForProgram = getFormsByProgram[id] || []
        
            const studentCompletedForms = getTypeForm
                .filter(
                (form: any) =>
                    form.response_type === 'Submitted - Needs Review' &&
                    form.form_id === 9 &&
                    form.student_uuid === student_uuid
                )
                .map((form: any) => form.form_id)
        
            const combinedCompletedForms = [...completedForms, ...studentCompletedForms];
            const completedFormsSet = new Set(combinedCompletedForms);
            const filterIncompleteForms = formsForProgram
                .filter((form: any) => !completedFormsSet.has(form.form_id))
                .map((form: any) => form.form_id)
        
            const currentForm = matchedForm.form_id
            const remainingForms = filterIncompleteForms
                .map(formId => formIdToFormId[formId])
                .filter(form => form && form !== currentForm)
    
            navigate(`/dashboard/${currentUser?.currentClient}/forms/${currentForm}`, { replace: true, state: {
                student_uuid: student_uuid,
                program_uuid: program_uuid,
                form_id: currentForm,
                remaining_forms: remainingForms,
                auth: currentUser?.currentClient,
                rawTypeformData: data,
                formsPage,
                sf_id
            }})
        } catch (error) {
            console.error("Error handling navigation:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const matchedStudentTypeform = getTypeForm.filter(
        (form: any) => {
            return form.student_uuid && String(form.student_uuid) === String(item.student_uuid);
        }
    );

    const matchedProgramTypeform = getTypeForm.filter(
        (form: any) => {
            return form.program_uuid && String(form.program_uuid) === String(item.program_uuid);
        }
    );

    const getCompletedForms = matchedProgramTypeform
        .filter((item: any) => item.response_type === 'Submitted - Needs Review')
        .map((item: any) => item.form_id)

    return (
        <>
            {isLoading ? (
                <tr>
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    </td>
                </tr>
                ) : (
                    <>
                        {getTypeformData.filter(typeform => typeform.name === 'Medical Form').map((typeform, index) => {
                            const form_id = typeform.id
                            
                            const correspondingTypeform = matchedStudentTypeform.find((typeform: { form_id: any; }) => typeform.form_id === form_id);

                            const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
                            const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;
                            
                            let status = 'Incomplete';
                            if (isCompleted) status = 'Completed';
                            else if (isSubmitted) status = 'Submitted - Needs Review';

                            return (
                                <tr key={index}>
                                    {index === 0 && (
                                        <td rowSpan={getTypeformData.length} style={{ verticalAlign: "middle", background: '#ccc', textAlign: 'center' }}>
                                        Application Form
                                        </td>
                                    )}
                                    <td className='px-1'>{typeform?.name || 'N/A'}</td>
                                    <td>{typeform?.overview || 'N/A'}</td>
                                    <td 
                                        className={`${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
                                        onClick={status === 'Incomplete' ? () => navigateForms(
                                        item.student_uuid, 
                                        item.selectedProgram.id, 
                                        item.program_uuid,
                                        form_id!,
                                        getCompletedForms,
                                        item?.contact_sf_id
                                        ) : undefined}
                                    >
                                        {status}
                                    </td>
                                    <td style={{ textAlign: 'center'}}>
                                        {!hasAdmin && status === 'Incomplete' ? (
                                        <Button 
                                            variant="primary"
                                            style={{ width: '100px' }}
                                            onClick={() => navigateForms(
                                            item.student_uuid, 
                                            item.selectedProgram.id, 
                                            item.program_uuid,
                                            form_id!,
                                            getCompletedForms,
                                            item?.contact_sf_id
                                            )}
                                        >
                                            Submit
                                        </Button>
                                        ) : (
                                            <>
                                                {correspondingTypeform && correspondingTypeform.action ? (
                                                    <>
                                                    {correspondingTypeform.action === 'resubmit' ? (
                                                        <p style={{ fontSize: '14px' }}>Resubmit requested</p>
                                                    ) : (
                                                        <p style={{ fontSize: '14px' }}>Update requested</p>
                                                    )}
                                                    </>
                                                ) : (
                                                    <>
                                                    <Button
                                                        variant="link"
                                                        style={{ textDecoration: 'underline', color: 'black' }}
                                                        onClick={() => handleShowModalConfirmation(item.student_uuid, item.program_uuid, form_id, 'resubmit')}
                                                    >
                                                        Request to resubmit
                                                    </Button>
                                                    <hr style={{ width: '75%', margin: '10px auto' }} />
                                                    <Button
                                                        variant="link"
                                                        style={{ textDecoration: 'underline', color: 'black' }}
                                                        onClick={() => handleShowModalConfirmation(item.student_uuid, item.program_uuid, form_id, 'update')}
                                                    >
                                                        Request to update
                                                    </Button>
                                                    </>
                                                )}
                                                {hasAdmin && (
                                                    <>
                                                        {!formsPage && (
                                                            <Button variant="secondary" style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }} 
                                                                onClick={() =>{handleShowModal(item.student_uuid, item.program_uuid, form_id, status, 'Medical')}} disabled={!correspondingTypeform}>
                                                                Form Status
                                                            </Button>
                                                        )}
                                                        
                                                        {correspondingTypeform && correspondingTypeform.action && (
                                                            <>
                                                                <Button variant="success" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( item.student_uuid, item.program_uuid, form_id, correspondingTypeform.action, 'Approve')}}>
                                                                    Approve
                                                                </Button>
                                                                <Button variant="danger" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( item.student_uuid, item.program_uuid, form_id, correspondingTypeform.action, 'Deny')}}>
                                                                    Deny
                                                                </Button>
                                                            </>
                                                        )}

                                                    </>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                            })}

                            {getTypeformData.filter(typeform => typeform.name !== 'Medical Form').map((typeform, index) => {
                            const form_id = typeform.id
                            
                            const correspondingTypeform = matchedProgramTypeform.find((typeform: { form_id: any; }) => typeform.form_id === form_id);
                            
                            const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
                            const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;
                            
                            let status = 'Incomplete';
                            if (isCompleted) status = 'Completed';
                            else if (isSubmitted) status = 'Submitted - Needs Review';

                            return (
                                <tr key={index}>
                                    <td className='px-1'>{typeform?.name || 'N/A'}</td>
                                    <td>{typeform?.overview || 'N/A'}</td>
                                    <td 
                                        className={`${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
                                        onClick={status === 'Incomplete' ? () => navigateForms(
                                        item.student_uuid, 
                                        item.selectedProgram.id, 
                                        item.program_uuid,
                                        form_id!,
                                        getCompletedForms,
                                        item?.contact_sf_id
                                        ) : undefined}
                                    >
                                        {status}
                                    </td>
                                    <td style={{ textAlign: 'center'}}>
                                        {!hasAdmin && status === 'Incomplete' ? (
                                        <Button 
                                            variant="primary"
                                            style={{ width: '100px' }}
                                            onClick={() => navigateForms(
                                            item.student_uuid, 
                                            item.selectedProgram.id, 
                                            item.program_uuid,
                                            form_id!,
                                            getCompletedForms,
                                            item?.contact_sf_id
                                            )}
                                        >
                                            Submit
                                        </Button>
                                        ) : (
                                            <>
                                                {correspondingTypeform && correspondingTypeform.action ? (
                                                    <>
                                                    {correspondingTypeform.action === 'resubmit' ? (
                                                        <p style={{ fontSize: '14px' }}>Resubmit requested</p>
                                                    ) : (
                                                        <p style={{ fontSize: '14px' }}>Update requested</p>
                                                    )}
                                                    </>
                                                ) : (
                                                    <>
                                                    <Button
                                                        variant="link"
                                                        style={{ textDecoration: 'underline', color: 'black' }}
                                                        onClick={() => handleShowModalConfirmation(item.student_uuid, item.program_uuid, form_id, 'resubmit')}
                                                    >
                                                        Request to resubmit
                                                    </Button>
                                                    <hr style={{ width: '75%', margin: '10px auto' }} />
                                                    <Button
                                                        variant="link"
                                                        style={{ textDecoration: 'underline', color: 'black' }}
                                                        onClick={() => handleShowModalConfirmation(item.student_uuid, item.program_uuid, form_id, 'update')}
                                                    >
                                                        Request to update
                                                    </Button>
                                                    </>
                                                )}
                                                {hasAdmin && (
                                                    <>
                                                        {!formsPage && (
                                                            <Button variant="secondary" style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }} 
                                                                onClick={() =>{handleShowModal(item.student_uuid, item.program_uuid, form_id, status)}} disabled={!correspondingTypeform}>
                                                                Form Status
                                                            </Button>
                                                        )}
                                                        
                                                        {correspondingTypeform && correspondingTypeform.action && (
                                                            <>
                                                                <Button variant="success" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( item.student_uuid, item.program_uuid, form_id, correspondingTypeform.action, 'Approve')}}>
                                                                    Approve
                                                                </Button>
                                                                <Button variant="danger" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( item.student_uuid, item.program_uuid, form_id, correspondingTypeform.action, 'Deny')}}>
                                                                    Deny
                                                                </Button>
                                                            </>
                                                        )}

                                                    </>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                )
            }
            
        </>
    );
}