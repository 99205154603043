import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import moment from 'moment';
import { axiosPrivate } from '../../axiosInstance';
import { NewAndIncAppFormRenderer } from './NewAndIncAppFormRenderer';
import ChangeStatusModal from '../modules/application/components/modal/ChangeStatusModal';
import ResubmitRequestModal from '../modules/application/components/modal/ResubmitRequestModal';
import ApproveDenyRequestModal from '../modules/application/components/modal/ApproveDenyRequestModal';

const SavedProgress = ({ savedProgress}: any) => {

  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showModalConfirmationApproveDeny, setShowModalConfirmationApproveDeny] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState({ success: '', error: ''});
  const [modalMessage, setModalMessage] = useState('');
  const [getAction, setAction] = useState('');
  const [isError, setIsError] = useState<string | null>(null);

  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [getCurrentStatus, setCurrentStatus] = useState<string>()
  const [formData, setFormData] = useState({
    studentUUID: null,
    programUUID: null,
    formID: null,
  });

  const [hasAdmin, setHasAdmin] = useState<any>(null)

  useEffect(() => {
    const getCurrentAuth = async () => {
      const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (PrevAuth) {
        setHasAdmin(true)
      } else {
        setHasAdmin(false)
      }
    }
    getCurrentAuth()
  }, []);

  const handleDelete = async (program_uuid: string) => {
    if (window.confirm("Are you sure you want to delete this program?")) {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.post(`/remove-incomplete-apps/${program_uuid}`);
        console.log("Program UUID:", program_uuid);

        if (response.status === 200) {
          alert(response.data.message); 
          window.location.reload();  
        }
      } catch (error) {
        console.error('Error while deleting:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleShowModal = async (student_uuid: any, program_uuid: any, form_id: any, status: string, message: string) => {
    setModalMessage(message);
    setSelectedStatus(status);
    setCurrentStatus(status)
    setShowModal(true)
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleShowModalConfirmation = (student_uuid: any, program_uuid: any, form_id: any, message: string) => {
    setModalMessage(message);
    setShowModalConfirmation(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleShowModalConfirmationApproveDeny = (student_uuid: any, program_uuid: any, form_id: any, action: string, message: string) => {
    setModalMessage(message);
    setAction(action);
    setShowModalConfirmationApproveDeny(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalConfirmation(false);
    setShowModalConfirmationApproveDeny(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmitStatusChange = async (student_uuid: any, program_uuid: any, form_id: any, message: string) => {
    if (selectedStatus === getCurrentStatus) {
      setIsError(`The form status is already set to '${getCurrentStatus}'. No changes will be made.`)

      setTimeout(() => {
        setIsError('')
      }, 3000)

      return;
    }

    try {
      let response;
      if (message === 'Medical'){
        response = await axiosPrivate.get('/api/typeform-updateStatusMedicalForm', {
          params: { student_uuid, form_id, selectedStatus},
        });
      } else {
        response = await axiosPrivate.get('/api/typeform-updateStatus', {
          params: { student_uuid, program_uuid, form_id, selectedStatus},
        });
      }

      if (response.data.isUpdated) {
        window.location.reload()
      }
      
    } catch (error) {
      setIsError('An error occurred while submitting the form: ' + error)
    }

    handleCloseModal()
  };

  const handleSubmitRequest = async(student_uuid: any, program_uuid: any, form_id: any, message: string, account_holder_uuid?: string) => {
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, type: message, account_holder_uuid}
      const response = await axiosPrivate.post('/api/typeform-request', data);

      if (response.status === 200) {
        setShowSuccessMessage({ success: response.data.message, error: ''})
      }
      
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while submitting the form: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApproveAndDenyRequest = async(student_uuid: any, program_uuid: any, form_id: any, action: any, message: string) =>{
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, action}
      if (message === 'Approve'){
        const response = await axiosPrivate.post('/api/typeform-approveRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})

        }
      } else {
        const response = await axiosPrivate.post('/api/typeform-denyRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})

        }
      }
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while processing the request: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

    const handleContinue = (data: any) => {
      const { form_data, current_step, program_uuid, selected_campus, selected_student } = data
        localStorage.setItem('multiStepFormData', JSON.stringify(form_data));
        localStorage.setItem('scholarshipCodes', JSON.stringify(form_data?.discounts))
        localStorage.setItem('currentStep', current_step);
        localStorage.setItem('selectedCampus', selected_campus);
        sessionStorage.setItem('selectedStudent', selected_student);
        sessionStorage.setItem('selectedAppType', form_data?.enrollment_track || '');
        sessionStorage.setItem('program_uuid', program_uuid);
        sessionStorage.setItem('tuition_plan', form_data?.tuition_plan);
        sessionStorage.setItem('tnc', form_data?.tnc);
        sessionStorage.setItem('selectedExtras', JSON.stringify(form_data?.enrollment_extras))
        sessionStorage.setItem('student_contact_id', form_data?.student_contact_id)

        navigate(`/application/${currentUser?.currentClient}/add-program`);
    };

    if (savedProgress?.length === 0) return null;

    return (
      <div>
        {savedProgress?.map((item: any, index: number) => {

          const student_uuid = item.student_info.uuid
          const studentName = `${item.student_info.first_name} ${item.student_info.last_name}`
          const { form_data } = item

          if (item.status === "enrolled") return null

          const campusName = form_data?.selectedProgram.programData.campus
          const tuitionName = form_data?.student_Type
          const sessionName = `${form_data?.selectedSession?.session_name} (${moment.utc(form_data.selectedSession.start_date).format('MM/DD/YYYY')} - ${moment.utc(form_data.selectedSession.end_date).format('MM/DD/YYYY')})`
          const programName = form_data.selectedProgram.name

          const getApplicationType = form_data?.enrollment_track

          return (
            <div key={index}> 
              <div className='card card-body my-3'>
                <div className='d-flex gap-3'>
                  <div className='d-flex flex-column gap-3'>
                    <span className='fs-3 text-center'>{studentName}</span>
                    <div className='d-flex flex-column' style={{ background: "#ccc", padding: '5px' }}>
                      <span>{campusName}:</span>
                      <span className='text-capitalize'>{tuitionName} Student</span>
                      <span>{sessionName}</span>
                      <span>{programName}</span>
                    </div>
                    <div style={{ background: "#f7942a", padding: '5px', fontSize: 15 }}>Application Status: {'Incomplete - Application Started'}</div>
                    <Button onClick={() => handleContinue(item)} variant='primary'>Click here to continue your application.</Button>
                    <Button 
                      className="btn btn-danger" 
                      onClick={() => handleDelete(item?.program_uuid)}
                    >
                      Delete
                    </Button>
                  </div>

                  {getApplicationType && getApplicationType.trim() !== '' && (
                    <div className='flex-grow-1' style={{ alignSelf: 'flex-start'}}>
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th>Section</th>
                            <th>Task Name</th>
                            <th>Overview</th>
                            <th>Status</th>
                            <th style={{ width: 150 }}>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ border: '1px solid #dee2e6' }}>
                          <NewAndIncAppFormRenderer
                            item={item.form_data}
                            handleShowModal={handleShowModal}
                            handleShowModalConfirmation={handleShowModalConfirmation}
                            handleShowModalConfirmationApproveDeny={handleShowModalConfirmationApproveDeny}
                            hasAdmin={hasAdmin}
                            formsPage={false}
                          />
                        </tbody>
                      </table>
                    </div>
                  )}

                </div>
              </div>
            </div>
          )
        })}

      <ChangeStatusModal 
        show={showModal} 
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        selectedStatus={selectedStatus || ""}
        handleChangeStatus={handleChangeStatus}
        isError={isError || ""} 
        hasAdmin={hasAdmin}
        handleSubmitStatusChange={handleSubmitStatusChange}
        formData={formData}
      />

      <ResubmitRequestModal 
        show={showModalConfirmation}
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        isLoading={isLoading}
        showSuccessMessage={showSuccessMessage}
        handleSubmitRequest={handleSubmitRequest}
        formData={formData}
        currentUser={currentUser}
      />

      <ApproveDenyRequestModal 
        show={showModalConfirmationApproveDeny}
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        isLoading={isLoading}
        showSuccessMessage={showSuccessMessage}
        handleApproveAndDenyRequest={handleApproveAndDenyRequest}
        formData={formData}
        getAction={getAction}
      />
      </div>
    );
};

export default SavedProgress;